<template>
  <div class="corpus-wrap">
    <van-nav-bar class="van-nav-bar--fixed" title="资料大全" left-arrow  right-text="开奖记录"  @click-left="onClickLeft"  @click-right="onClickRight"></van-nav-bar>
    <div class="search-hd">
            
            <van-search class="van-search van-search--show-action"
                v-model="value"
                placeholder="请输入搜索关键词"
                @search="onSearch"
                shape="round"
                background="#4fc08d"
            
            >
            </van-search>
            
         
    </div>

    <div class="search-list clearfix">
        <div class="search-item" v-for="item in list" :key="item.id" @click="goToUrl(item.id)">
            <div>{{item.name}}</div>
        </div>
    </div>
  </div>
</template>

<script>
 

export default {
  data() {
    return {
      year_id:1,
      lotto_id:2,
      active: 'picture',
      list:[],
      loading: false,
      finished: false,
      iserror:false,
      page:1,
      message:'',
      value:'',
      iscolour: 0,
    };
  },
  mounted() {
        //this.active='picture';
        this.lotto_id= parseInt(this.$route.query.lotto_id) ? parseInt(this.$route.query.lotto_id)  : 2;
        this.getData();
    },
  methods: {
        onClickLeft() {
            // 返回上一级页面
            this.$router.back();
        },
        onClickRight() {
            this.$router.push({ path: '/kaijiang'});
        },
        goToUrl(id){
            this.$router.push({ path: '/corpus/list?id='+id+'&lotto_id='+this.lotto_id});
        },
        onSearch(){
            this.getData();
        },
        getData() {
            this.$http
                .post("api/corpus/category",{search:this.value,lotto_id:this.lotto_id})
                .then(function (response) {
                   
                    if (response.data.code == "ok") {
                        this.list = response.data.data.list;
                        this.loading = false;
                    } else {
                        this.iserror = true;
                        this.message = response.data.msg;
                    }
                    this.loading = false;
                   //console.log(this.list);
                });
    
        },
    }
}
</script>
<style>
.van-search {
    margin-top: 50px;
}
.corpus-wrap .search-list {
    margin-bottom: 50px;
}
</style>